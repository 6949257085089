<template>
    <section id="skills" class="section">
        <h2>Mine Ferdigheter</h2>
        <div class="skills-buttons">
            <span v-for="(skill, index) in skills" :key="index" class="skill-button">
            {{ skill }}
        </span>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'MySkills',
        data() {
            return {
                skills: [
                    'ADHD',
                    'Alvorlig sykdom og helseutfordringer',
                    'Angst',
                    'Autisme',
                    'Barsel og svangerskap',
                    'Belastende livshendelser',
                    'Depresjon',
                    'Engelsk',
                    'Fertilitet',
                    'Fobier',
                    'Foreldreveiledning',
                    'Identitet',
                    'Jobbrelaterte utfordringer',
                    'Karriere',
                    'Kultur og tilpasning',
                    'Kjønnsidentitet og LGBTQ+',
                    'Livsvalg',
                    'Mobbing og trakassering',
                    'Minoritetsstress',
                    'Norsk',
                    'OCD/Tvang',
                    'Overtenking og bekymring',
                    'Pårørende',
                    'Psykose',
                    'Relasjonelle vansker',
                    'Rus og avhengighet',
                    'Samlivsvansker',
                    'Selvfølelse',
                    'Selvskading',
                    'Sex og seksuell helse',
                    'Skole- og studierelaterte vansker',
                    'Sorg og tap',
                    'Sosial angst',
                    'Søvnvansker',
                    'Stress og utbrenthet',
                    'Tilknytningsvansker',
                    'Traumer/PTSD',
                    'Urdu',
                    'Vold og overgrep',
                ],
            };
        },
    };
</script>

<style scoped>
    .section {
        padding: 10px 20px;
        min-height: 50vh;
    }

    .skills-buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }

    .skill-button {
        padding: 8px 16px;
        border: 1px solid #007bff;
        border-radius: 5px;
        background-color: #f0f8ff;
        color: #007bff;
        font-size: 14px;
        cursor: default; /* Sørg for at knappene ikke virker klikkbare */
        user-select: none;
    }

    @media (max-width: 768px) {
        .section {
            padding-top: 50px;
        }
    }
</style>
