<template>
    <footer class="footer">
        <p>&copy; {{ currentYear }} Mentio - PSYKOLOG SUNBL YOUNIS</p>
    </footer>
</template>

<script>
export default {
    name: 'MainFooter',
    data() {
        return {
            currentYear: new Date().getFullYear(), // Henter det aktuelle året
        };
    },
};
</script>

<style scoped>
.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #333; /* Mørk bakgrunn */
    color: #fff; /* Hvit tekst */
    text-align: center;
    padding: 10px 0; /* Litt padding for bedre lesbarhet */
    margin-bottom: 0px; /* Juster avstand til innhold */;
    font-size: 14px; /* Juster fontstørrelse */
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Litt skygge for dybde */
    z-index: 1000; /* Sørg for at footeren ligger over annet innhold */
}
</style>
