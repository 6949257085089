<template>
    <section id="about-me" class="section">
      <div class="about-container">
        <div class="text-box">
          <img src="@/assets/sunbl.png" alt="Bilde av meg" class="p-avatar p-avatar-circle image-top-right" />
          <h2>Om meg</h2>
          <p>
            Jeg er en autorisert psykolog og har jobbet ved BUP, PP-tjenesten, skole- og kommunehelsetjenesten, og jobber til
            daglig ved et familievernkontor. Jeg har kompetanse innenfor arbeid med barn og unge voksne og har jobbet med et bredt
            spekter av psykiske lidelser, særlig depresjon- og angstproblematikk, samt nevropsykologiske tilstander som ADHD og
            autismespekterforstyrrelse. 
            <br /><br />
            Jeg er god på å hjelpe mennesker som opplever traumer eller akutte kriser i livet. Jeg
            liker å jobbe med å finne ens egne styrker og mestringsstrategier for en positiv endring gjennom å sette konkrete mål.
            Jeg har en behagelig framtreden og er flink til å bygge relasjoner, skape tillit og motivere den enkelte for å nå sine
            mål. Jeg er under spesialisering for barne- og ungdomspsykologi. Jeg snakker flytende norsk, engelsk, urdu og punjabi.
          </p>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'AboutMe',
  };
  </script>
  
  <style scoped>
  .section {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    padding: 60px 20px;
    min-height: 50vh;
  }
  
  .about-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  
  .text-box {
    position: relative; /* Viktig for å kunne posisjonere bildet */
    background-color: #fff;
    padding: 20px;
    padding-top: 60px; /* Legg til ekstra topp-padding for å unngå overlapp */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* max-width: 500px; */
  }
  
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .p-avatar-circle {
    width: 150px;
    height: 150px;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .image-top-right {
    position: absolute; /* Plasser bildet absolutt i forhold til tekstboksen */
    top: -30px; /* Juster for ønsket posisjon */
    right: -30px; /* Juster for ønsket posisjon */
    width: 100px; /* Juster bildestørrelse etter behov */
    height: 100px;
    object-fit: cover;
    border: 3px solid #fff; /* En liten kant for å fremheve bildet */
    border-radius: 50%; /* Gjør bildet sirkulært */
  }

  /* style for mobile */
    @media (max-width: 768px) {
        .text-box {
            padding-top: 30px; /* Juster topp-padding for å unngå overlapp */
        }
    
        .image-top-right {
            top: -15px; /* Juster for ønsket posisjon */
            right: -15px; /* Juster for ønsket posisjon */
            
        }
    }
</style>
