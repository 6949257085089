<template>
  <div id="app">
    <header class="header">
      <img src="@/assets/widelogo.png" alt="Logo" class="logo" />
      <nav class="menu">
        <ul>
          <li><a href="#about-me" @click.prevent="scrollTo('about-me')">Om meg</a></li>
          <li><a href="#skills" @click.prevent="scrollTo('skills')">Kompetanse</a></li>
          <li class="desktop-only"><a href="#booking" @click.prevent="scrollTo('booking')">Bestill time</a></li>
          <!-- <li><a href="#contact" @click.prevent="scrollTo('contact')">Kontakt</a></li> -->
        </ul>
      </nav>
    </header>
    <main>
      <div class="main-inner">
        <AboutMe class="section" />
        <MySkills class="section" />
        <AddBooking class="section" />
        <MyReviews class="section" />
        <MainFooter class="section" />
      </div>
    </main>
    <button class="fixed-booking-button" @click="openBookingPage">
      <i class="pi pi-calendar-plus"></i> Bestill time
    </button>
  </div>
</template>

<script>
import AboutMe from './components/AboutMe.vue';
import MySkills from './components/MySkills.vue';
import AddBooking from './components/AddBooking.vue';
// import ContactMe from './components/ContactMe.vue';
import MainFooter from './components/MainFooter.vue';

export default {
  components: {
    AboutMe,
    MySkills,
    AddBooking,
    // ContactMe,
    MainFooter,
  },
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    scrollTo(section) {
      this.isMenuOpen = false; // Lukk menyen etter navigering
      const element = document.getElementById(section);
      const headerOffset = 100; // Juster denne verdien til høyden på headeren
      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    },
    openBookingPage() {
      window.open('https://booking.konfidens.no/psykologsunbl', '_blank');
    },
  },
};
</script>

<style>
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #b2beba;
  overflow-x: hidden; /* Forhindre sideveis scroll */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #b2beba;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap; /* Lar elementene brytes på mobil */
}

.menu {
  display: flex;
  gap: 20px;
  align-items: center;
}

.menu ul {
  list-style: none;
  display: flex;
  gap: 10px;
  margin: 0;
  padding: 0;
}

.menu a {
  text-decoration: none;
  color: #333;
  padding: 10px 20px;
  border: 2px solid #333; /* Border med #333 */
  border-radius: 5px;
  background-color: #f7f7f7; /* Lys grå bakgrunn */
  transition: background-color 0.3s ease, border-color 0.3s ease; /* Smooth overgang */
}

.menu a:hover {
  background-color: #e0e0e0; /* Litt mørkere grå ved hover */
  border-color: #333; /* Behold grensen ved hover */
}

.logo {
  height: 80px;
}

.main-inner {
  max-width: 586px;
  margin: 0 auto;
  padding-top: 150px; /* Juster for å gi plass til den faste headeren */
}

.section {
  margin: 40px 0; /* Konsistente marginer mellom seksjonene */
}

.fixed-booking-button {
  display: none; /* Skjult som standard */
  position: fixed;
  bottom: 80px; /* Juster avstanden slik at knappen kommer over footeren */
  left: 50%;
  transform: translateX(-50%);
  background-color: #4caf50;
  color: #fff;
  padding: 15px 30px;
  font-size: 18px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* Sørg for at den ligger over footeren */
  transition: background-color 0.3s ease;
}

.fixed-booking-button:hover {
  background-color: #45a049; /* Litt mørkere grønn ved hover */
}

.pi-calendar-plus {
  font-size: 24px; /* Størrelse på ikonet */
}

@media (max-width: 768px) {
  .header {
    flex-direction: column; /* Plasserer elementene i kolonne på mobil */
    align-items: center;
  }

  .menu {
    justify-content: center; /* Sentrer knappene under logoen */
    width: 100%; /* Sørger for at menyen strekker seg over hele bredden */
    height: 40px;
    margin-top: 5px;
  }

  .menu ul {
    justify-content: center;
    flex-wrap: wrap; /* Gjør menyen fleksibel på mobil */
    gap: 10px; /* Mindre gap mellom knappene */
  }

  .menu a {
    font-size: 14px; /* Juster fontstørrelsen på mobil */
    padding: 8px 12px; /* Juster padding for mobil */
  }

  .main-inner {
    padding-top: 170px; /* Juster for å gi plass til den faste headeren */
  }
  
  .desktop-only {
    display: none; /* Skjul "Bestill time"-menypunktet på mobil */
  }

  .fixed-booking-button {
    display: flex; /* Vis knappen på mobilskjermer */
  }

  .section {
    margin-top: 60px; /* Økt margin for bedre lesbarhet på mobil */
  }
}
</style>
