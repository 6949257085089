<template>
    <section id="booking" class="section">
      <div class="text-box">
        <h2>Bestill time</h2>
        <p>
          Ønsker du å bestille en konsultasjon? Du kan enkelt gjøre det ved å klikke på knappen nedenfor.
          Ved klikk vil du bli tatt til vår bookingside hvor du kan velge en tid som passer for deg.
        </p>
      </div>
      <button class="booking-button" @click="openBookingPage">
        <i class="pi pi-calendar-plus"></i> Bestill time
      </button>
    </section>
  </template>
  
  <script>
  export default {
    name: 'AddBooking',
    methods: {
      openBookingPage() {
        window.open('https://booking.konfidens.no/psykologsunbl', '_blank');
      },
    },
  };
  </script>
  
  <style scoped>
  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
    min-height: 50vh;
  }
  
  .text-box {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    text-align: center;
    margin: 40px 0 20px; /* Juster margintopp for mer plass */
  }
  
  .booking-button {
    background-color: #4caf50; /* Grønn farge for positiv handling */
    color: #fff;
    padding: 15px 30px;
    font-size: 18px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
  }
  
  .booking-button:hover {
    background-color: #45a049; /* Litt mørkere grønn ved hover */
  }
  
  .pi-calendar-plus {
    font-size: 24px; /* Størrelse på ikonet */
  }
  </style>
  